import './App.css';
import NavBar from './NavBar';
import Experience from './Experience';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './Home';
import About from './About';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path ="/">
            <Home />
            <Experience />
          </Route>
          <Route exact path ="/about">
            <About/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

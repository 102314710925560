import Franky from './franky2.png';
const Home = () => {
    return (
        <div className="content">
          <div className="info">
            <img className="personal" src={Franky} alt=''/>
            <h1>Franky Jiang</h1><br />
            <h2>Computer Science Student at Ryerson University</h2><br />
            <p>Hi, my name is Franky and I'm an undergraduate Computer Science student hoping to further my experience and knowledge in the field.
              I'm looking forward to exploring new technologies and expanding my current skillset of Java, Python and React.</p>
          </div>
          <ul className="box-area">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
    );
}
 
export default Home;
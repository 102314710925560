import './index.css';
import github_light from './github_logo_light.png';
import github_dark from './github_logo_dark.png';
const NavBar = () => {
    function over() {
        document.querySelector(".github").src = github_dark;
    }

    function out() {
        document.querySelector(".github").src = github_light;
    } 
    return (
        <nav className="navbar">
            <h1>Franky Jiang</h1>
            <div className="links">
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="https://github.com/IFood125"
                onMouseOver={over}
                onMouseOut={out}
                >
                    Projects
                    <img className="github" src={github_light} alt=''></img>
                </a>
            </div>
        </nav>
    );
}
 
export default NavBar;
import SE from "./SE.jpeg"
const Experience = () => {
    return (
        <div className="experience">
            <img src={SE} alt=''/>
            <h1>
                Software Developer at Service Echo
            </h1>
            <ul>
                <li>Integrated the Service Echo app with the Quickbooks API</li>
                <li>Created unit tests and bug fixes for the Service Echo package</li>
                <li>Performed Salesforce org migrations using the metadata API</li>
            </ul>
        </div>
    );
}
 
export default Experience;